import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const ImageBanner = styled.div`
  min-height: 334px;
  width: 100%;
  background: url(${'/images/frontpagebanner.png'});
  display: flex;
`

const BannerHeader = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  color: #ffffff;
`

const BannerBreadtext = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin-top: 10px;
  color: #fff;
`

const BannerTextContainer = styled.div`
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  text-align: center; // optional, but helps horizontally center text that breaks into multiple lines
  flex-direction: column;
  margin: auto;
  padding: 3em 1em;
  max-width: 750px;
`

const StyledLink = styled(Link)`
  font-weight: bold;
  color: white;
  border: none;
`

// @ts-ignore
interface Props {
  bannerHeaderText?: string
  bannerText?: string
  bannerLink?: string
  bannerLinkText?: string
}

const FrontPageBanner: React.FC<Props> = ({ bannerHeaderText, bannerText, bannerLink, bannerLinkText }) => {
  const headerText: string = bannerHeaderText?.length ? bannerHeaderText : 'Tämä on Radio Dein monimedia!'
  const text: string = bannerText?.length
    ? bannerText
    : 'Deila on kanssasi kellon ympäri, ja nyt valinta on sinun. Avaa korvat ja kuuntele Radio Deitä suorana, sukella arkistoon, nauti juuri sinun musiikistasi tai perehdy podcasteihin.'

  return (
    <ImageBanner>
      <BannerTextContainer>
        <BannerHeader>{headerText}</BannerHeader>
        <BannerBreadtext>
          {text}
          {bannerLink?.length && ' '}
          {bannerLink?.length && <StyledLink to={bannerLink}>{bannerLinkText ? bannerLinkText : 'linkki'}</StyledLink>}
        </BannerBreadtext>
      </BannerTextContainer>
    </ImageBanner>
  )
}
export default FrontPageBanner
