import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import FrontPageBanner from '../components/FrontPageBanner/FrontPageBanner'
import { SearchBar } from '../components/SearchBar'

const NotFoundPage = () => (
  <Page>
    <Container>
      <FrontPageBanner bannerHeaderText="404 - Sivua ei löydy!" bannerText="Sivun linkki on saattanut vanhentua, tai etsimäsi sisältö on poistettu. Kokeile hakua tai hae uutta vauhtia" bannerLink="/" bannerLinkText="etusivulta" />
    </Container>
  </Page>
)

export default NotFoundPage
